import React, { Fragment } from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from '../styles/buttons'
import { Card, CardBody } from "../styles/card"
import { FlexBlock, GridBlock, Section, Spacer, Wrapper } from "../styles/layout"
import { BodyCopy2, CardSubTitle, Heading1, Heading4, Heading5, Heading6, LabelText, ParagraphFaded, PriceMainText, Title } from "../styles/typography"
import IconSchoger from "../ui/icons/IconSchoger"
import * as analytics from '../analytics/analytics'

export const pageQuery = graphql`
  query PricingQuery{
    allStripePlan {
        edges {
          node {
            product
            id
            active
            interval
            currency
            amount
          }
        }
      }
    }
`


const features = [
  {
    title: 'One tap quick start',
    free: true,
    premium: true,
  },
  {
    title: 'Automatic shot selection',
    free: true,
    premium: true,
  },
  {
    title: 'Create your own tasks',
    free: true,
    premium: true,
  },

  {
    title: 'Upload 10mb media',
    free: true,
    premium: false,
  },
  {
    title: 'Upload 50mb media',
    free: false,
    premium: true,
  },
  {
    title: 'Access our library of tasks and sessions',
    free: true,
    premium: true,
  },
  {
    title: 'Play our sessions',
    free: true,
    premium: true,
  },
  {
    title: 'Use preset training templates',
    free: false,
    premium: true,
  },
  {
    title: 'Save your own session',
    free: false,
    premium: true,
  },
  {
    title: 'Play your own session',
    free: false,
    premium: true,
  },
];

const freeFeatures = () => {
  return features.filter(feat => feat.free === true).map(feature => (
    <Fragment key={feature.title} >
      <IconSchoger icon="tick" context="success" />
      <BodyCopy2 margin>{feature.title}</BodyCopy2>
    </Fragment>
  ))
}
const premiumFeatures = () => {
  return features.filter(feat => feat.premium === true).map(feature => (
    <Fragment key={feature.title}>
      <IconSchoger icon="tick" context="success" />
      <BodyCopy2 margin>{feature.title}</BodyCopy2>
    </Fragment>
  ))
}

const PricingPage = ({ data }) => {

  const { allStripePlan } = data // data.markdownRemark holds your post data
  const { edges } = allStripePlan


  const monthlyPlan = edges.find(edge => edge.node.active === true && edge.node.amount !== 0 && edge.node.interval === "month")
  const annualPlan = edges.find(edge => edge.node.active === true && edge.node.amount !== 0 && edge.node.interval === "year")

  const login = (position) => {
    analytics.handleSignUpClick('pricing');
    window.location.href = `${process.env.GATSBY_FRONT_END_ADDRESS}/login`
  }

  return (
    <Layout className="page">
      <SEO
        title="Catch our early adopter deals with golfBeta"
        description="Straightforward, early adopter pricing - bringing a great value golf practice app to help you to train smarter to improve faster."
      />

      <Wrapper>
        <Spacer height={15} />
        <FlexBlock direction="column" align="center">
          <Heading1>Pricing</Heading1>
          <Heading4>Our straightforward, early adopter pricing</Heading4>
        </FlexBlock>
      </Wrapper>
      <Spacer height={15} />
      <Section dark>
        <Wrapper>
          <FlexBlock direction="column" justify="center" style={{ textAlign: 'center' }}>
            <BodyCopy2>We need your feedback 😃! Drop us a message to discuss and get 1 year membership free after our chat!</BodyCopy2>
            <Spacer height={5} />
            <GridBlock columns="1fr 1fr" responsive style={{ width: '100%', marginTop: '-200px' }}>
              <Card style={{ width: '100%' }}>
                <CardBody nicePadding>
                  <FlexBlock direction="column" justify="center">
                    <Title>Free</Title>
                    <CardSubTitle>See if it is right for you</CardSubTitle>
                    <Spacer height={5} />
                    <FlexBlock align="baseline">
                      <PriceMainText>£0 </PriceMainText>
                    </FlexBlock>
                    <LabelText>No credit card required</LabelText>
                  </FlexBlock>
                </CardBody>
                <CardBody color nicePadding>
                  <Spacer height={3.2} />
                  <Heading5>What's included?</Heading5>
                  <Spacer height={3.2} />
                  <GridBlock columns="50px auto">
                    {freeFeatures()}
                  </GridBlock>
                </CardBody>
              </Card>
              <Card style={{ width: '100%' }}>
                <CardBody nicePadding>
                  <FlexBlock direction="column" justify="center">
                    <Title>Annual subscription</Title>
                    <CardSubTitle>Get four months free!</CardSubTitle>
                    <Spacer height={5} />
                    <FlexBlock align="baseline">
                      <PriceMainText>£{(annualPlan.node.amount / 100 / 12).toFixed(2)} </PriceMainText>
                      <Spacer width={0.6} />
                      <Heading6 color="lightgrey"> / mo</Heading6>
                    </FlexBlock>
                    <LabelText>billed as £{annualPlan.node.amount / 100} annually</LabelText>
                  </FlexBlock>
                </CardBody>
                <CardBody color nicePadding>
                  <Spacer height={3.2} />
                  <Heading5>What's included?</Heading5>
                  <Spacer height={3.2} />
                  <GridBlock columns="50px auto">
                    {premiumFeatures()}
                  </GridBlock>
                </CardBody>
              </Card>
            </GridBlock>
            <FlexBlock direction="column" justify="center">

              <Spacer height={5} />
              <ParagraphFaded style={{ maxWidth: '350px' }}>
                Not ready to commit yet?
              </ParagraphFaded>
              <ParagraphFaded style={{ maxWidth: '350px', textAlign: 'center' }}>
                <StyledSpan onClick={() => login()}>
                  Sign up for free
                </StyledSpan>
                {' '}to check us out and try out some limited features
              </ParagraphFaded>
              <Spacer height={3.2} />
              <Button
                type="CTA"
                onClick={() => {
                  login();
                }}
              >
                Start improving now!
        </Button>
            </FlexBlock>
            <Spacer height={5} />

          </FlexBlock>
        </Wrapper>

      </Section>
    </Layout>
  )
}

export default PricingPage

const StyledSpan = styled.span`
  color: ${(props) => props.theme.secondary};
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;